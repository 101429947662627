<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap>
            <v-col cols="9">
                <!-- Page view -->
                <router-view :key="reloadPage" :applyFilters="applyFilters" @update-view="reloadView" @condominiums-count="condominiumsCount"></router-view>
                <!-- end -->
            </v-col>
            <v-col  class="mt-12">
                <PropertiesFilters block="condominiums" :countingProperties="countingCondominiums" @filter-selected="filterSelected" 
                class="mt-16 mr-12"/>
            </v-col>
        </v-layout>
    </v-container>
</template>
  
<script>
  export default {
    name: 'Properties',
    data() {
      return {
        applyFilters: null,
        reloadPage: 0,
        countingCondominiums: 0
      }
    },
    methods:{
      filterSelected(val) {
          this.reloadPage += 1
          this.applyFilters = val
      },
      reloadView(){
        this.reloadPage+=1
      },
      condominiumsCount(v){
        this.countingCondominiums = v
      }
    }
  }
</script>
